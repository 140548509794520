import { Modal } from 'react-bulma-components';
import styles from './index.module.scss';

function ModalWrapper(props: {
  children: any;
  className?: string;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'full';
  show?: boolean;
  onClose?: () => void;
}) {
  const size = props.size || 'medium';

  return (
    <Modal
      showClose={props.onClose !== undefined}
      show={props.show}
      onClose={props.onClose}
    >
      <Modal.Card
        className={
          styles[size] + (props.className ? ' ' + props.className : '')
        }
      >
        {props.children}
      </Modal.Card>
    </Modal>
  );
}

export default ModalWrapper;
