import { useContext, useEffect, useState } from 'react';
import { Navbar } from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../context/auth-context';

import styles from './index.module.scss';
import MyNavbarLogout from './logout';

function MyNavbar() {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setShowBurgerMenu(false);
  }, [user]);

  const { t } = useTranslation();

  return (
    <Navbar active={showBurgerMenu}>
      <Navbar.Brand>
        <Navbar.Item>
          <img src="/images/logo.png" className={styles.logo} alt="Logo" />
          {/* <img height="27" src="/images/logo.png" alt="Logo" /> */}
        </Navbar.Item>

        {user && (
          <Navbar.Burger
            className={styles.burger + ' mt-3'}
            onClick={() => setShowBurgerMenu(!showBurgerMenu)}
          />
        )}
      </Navbar.Brand>

      {user && (
        <Navbar.Menu className={styles.menu}>
          <Navbar.Container align="right">
            <Navbar.Item className="nav-item-no-hover">{user.name}</Navbar.Item>
            <Navbar.Item>
              <MyNavbarLogout />
            </Navbar.Item>
          </Navbar.Container>
        </Navbar.Menu>
      )}
    </Navbar>
    // <div className={styles.container}>
    //   <img src="/images/logo.png" className={styles.logo} />
    // </div>
  );
}

export default MyNavbar;
