import styles from './index.module.scss';

function Content(props: { width: string; children: any }) {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper} style={{ maxWidth: props.width }}>
        {props.children}
      </div>
    </div>
  );
}

export default Content;
