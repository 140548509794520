import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { TwoFactorSecret } from '../../../../interfaces/two-factor-secret.interface';

function TwoFactorSecretItem(props: { item: TwoFactorSecret }) {
  const { t } = useTranslation();

  return (
    <div className="my-5">
      <QRCode value={props.item.qrcode} className="mb-2" />
      <p>{props.item.secret}</p>
    </div>
  );
}

export default TwoFactorSecretItem;
