import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { TimeRecording } from '../../../../interfaces/time-recording.interface';
import styles from './index.module.scss';

function TimeRecordingListItem(props: {
  item?: TimeRecording;
  onSelect?: (item: TimeRecording) => void;
}) {
  const { t } = useTranslation();

  let className = styles.container;

  if (!props.item) {
    className += ' ' + styles.empty;
  } else if (props.item.locked) {
    className += ' ' + styles.locked;
  } else if (props.item.error) {
    className += ' ' + styles.error;
  }

  const onSelect = () => {
    if (props.item && !props.item.locked && props.onSelect) {
      props.onSelect(props.item);
    }
  };

  return (
    <div className={className} onClick={onSelect}>
      {props.item && (
        <>
          <span className={styles.date}>
            {format(new Date(props.item.date), 'dd.MM.yyyy')}
          </span>
          <span className={styles.time}>
            {props.item.startTime} -{' '}
            {props.item.endTime || props.item.startTime}
          </span>
          <span>{props.item.projectName}</span>
          <span>{props.item.description}</span>
          {!props.item.returnMsg && <span>{props.item.status}</span>}

          {props.item.returnMsg && (
            <span className="has-text-danger">{props.item.returnMsg}</span>
          )}

          {props.item.error && (
            <span className="has-text-danger">{props.item.error}</span>
          )}
        </>
      )}
      {!props.item && <span>empty</span>}
    </div>
  );
}

export default TimeRecordingListItem;
