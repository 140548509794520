import { useTranslation } from 'react-i18next';
import { TimeRecording } from '../../../interfaces/time-recording.interface';
import styles from './index.module.scss';
import TimeRecordingListItem from './item';

function TimeRecordingList(props: {
  items: TimeRecording[];
  onSelect: (item: TimeRecording) => void;
}) {
  const { t } = useTranslation();
  const items = props.items.map((item) => {
    return (
      <TimeRecordingListItem
        item={item}
        onSelect={props.onSelect}
        key={
          item.date +
          '-' +
          item.startTime +
          '-' +
          item.endTime +
          '-' +
          item.error
        }
      />
    );
  });

  const minRows: number = +process.env.REACT_APP_MIN_RECORDING_ROWS!;

  if (items.length < minRows) {
    const max = minRows - items.length;

    for (let i = 0; i < max; i++) {
      items.push(<TimeRecordingListItem />);
    }
  }

  return <div className={styles.container}>{items}</div>;
}

export default TimeRecordingList;
