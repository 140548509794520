import { joiResolver } from '@hookform/resolvers/joi';
import axios from 'axios';
import Joi from 'joi';
import { useContext, useState } from 'react';
import { Button, Form, Icon } from 'react-bulma-components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { login } from '../../api/auth';
import AuthContext from '../../context/auth-context';
import { ReactComponent as User } from '../../svgs/user.svg';
import { ReactComponent as Shield } from '../../svgs/shield.svg';
import ErrorNotification from '../shared/error-notification';
import PasswordInput from '../shared/form/password';

const schema = Joi.object({
  username: Joi.string().required(),
  password: Joi.string().required(),
  code: Joi.string().required(),
  remember: Joi.boolean(),
});

function LoginForm(props: { onLogin?: () => void }) {
  const { t } = useTranslation('translation');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const authContext = useContext(AuthContext);

  const methods = useForm({
    resolver: joiResolver(schema),
    mode: 'all',
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    setError(undefined);

    try {
      const user = await login(
        values.username,
        values.password,
        values.code,
        values.remember
      );
      authContext.login(user, values.remember);

      if (props.onLogin) {
        props.onLogin();
      }
    } catch (err) {
      console.error('failed to login', err);

      methods.setValue('password', '');
      methods.setValue('code', '');

      if (
        axios.isAxiosError(err) &&
        err.response!.data.message === 'Username or password is wrong.'
      ) {
        setError(t('Login.errors.auth')!);
      } else {
        setError(t('Login.errors.unexpected')!);
      }
    }

    setLoading(false);
  };

  return (
    <FormProvider {...methods}>
      <ErrorNotification error={error} onClose={() => setError(undefined)} />
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Form.Field className="mb-3">
          <Controller
            render={({ field }) => (
              <Form.Control>
                <Form.Input
                  placeholder={t('Login.username')!}
                  name="username"
                  onBlur={() => field.onBlur()}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value}
                  domRef={field.ref as any}
                  maxLength={1000}
                  color={methods.formState.errors.username ? 'danger' : 'text'}
                  disabled={loading}
                />
                <Icon align="left">
                  <User />
                </Icon>
              </Form.Control>
            )}
            control={methods.control}
            name="username"
          />
        </Form.Field>
        <Form.Field className="mb-3">
          <PasswordInput disabled={loading} />
        </Form.Field>
        <Form.Field className="mb-1">
          <Controller
            render={({ field }) => (
              <Form.Control>
                <Form.Input
                  placeholder={t('Login.code')!}
                  name="code"
                  onBlur={() => field.onBlur()}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value}
                  domRef={field.ref as any}
                  maxLength={6}
                  color={methods.formState.errors.code ? 'danger' : 'text'}
                  disabled={loading}
                />
                <Icon align="left">
                  <Shield />
                </Icon>
              </Form.Control>
            )}
            control={methods.control}
            name="code"
          />
        </Form.Field>
        <Form.Field className="mb-3">
          <Controller
            render={({ field }) => (
              <Form.Checkbox
                className="checkbox--small"
                name="remember"
                onBlur={() => field.onBlur()}
                onChange={(e) => field.onChange(e.target.checked)}
                checked={field.value}
                domRef={field.ref as any}
                disabled={loading}
              >
                <span>{t('Login.remember')}</span>
              </Form.Checkbox>
            )}
            control={methods.control}
            name="remember"
          />
        </Form.Field>
        <Button.Group>
          <Button
            color="primary"
            fullwidth
            className="highlight"
            disabled={loading}
          >
            {t('Login.submit')}
          </Button>
        </Button.Group>
      </form>
    </FormProvider>
  );
}

export default LoginForm;
