import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../../api/auth';
import AuthContext from '../../../../context/auth-context';
import { loginUrl } from '../../../../utils/routes';

import styles from './index.module.scss';

function MyNavbarLogout() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const onLogout = async () => {
    setLoading(true);

    await toast.promise(logout(), {
      loading: t('Navbar.loggingOut')!,
      success: () => {
        authContext.logout();
        navigate(loginUrl);
        return t('Navbar.loggedOut')!;
      },
      error: t('Navbar.logoutFailed')!,
    });

    setLoading(false);
  };

  let className = styles.logout + ' has-text-purple';

  if (loading) {
    className += ' ' + styles['logout--loading'];
  }

  return (
    <p className={className} onClick={onLogout}>
      {t('Navbar.logout')}
    </p>
  );
}

export default MyNavbarLogout;
