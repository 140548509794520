import { Form, Icon } from 'react-bulma-components';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DateIcon } from '../../../../svgs/date.svg';
// import { DatePicker } from '@mui/x-date-pickers';

function DateInput(props: { fieldName?: string; disabled?: boolean }) {
  const { t } = useTranslation('translation');

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const fieldName = props.fieldName || 'date';

  return (
    <Form.Control>
      <Controller
        render={({ field }) => (
          <DatePicker
            dateFormat="dd.MM.yyyy"
            selected={field.value}
            onChange={(date) => field.onChange(date)}
            onBlur={() => field.onBlur()}
            ref={(ref) => {
              if (ref) {
                field.ref({
                  focus: ref!.setFocus,
                });
              }
            }}
            className={'input' + (errors[fieldName] ? ' is-danger' : '')}
            maxDate={new Date()}
            disabled={props.disabled}
          />
        )}
        control={control}
        name={fieldName}
      />
      <Icon align="right">
        <DateIcon />
      </Icon>
    </Form.Control>
  );
}

export default DateInput;
