import { Button, Modal } from 'react-bulma-components';
import { useTranslation } from 'react-i18next';
import ModalWrapper from '../modal-wrapper';

function SimpleModal(props: {
  onClose: () => void;
  // title: string;
  message: string;
  positiveButton?: string;
  cancelButton?: string;
  closeOnly?: boolean;
  onPositiveClick?: () => void;
}) {
  const { t } = useTranslation();

  function onPositiveClick() {
    props.onClose();
    props.onPositiveClick!();
  }

  return (
    <ModalWrapper size="small" show={true} onClose={props.onClose}>
      {/* <ModalHeader>{props.title}</ModalHeader> */}
      <Modal.Card.Body>
        <p className="mt-2">{props.message}</p>
        <div className="is-flex is-justify-content-end mt-5">
          {!props.closeOnly && (
            <Button color="primary" onClick={props.onClose} mr={3}>
              {props.cancelButton || t('General.cancel')}
            </Button>
          )}
          <Button
            className="highlight"
            color="primary"
            onClick={onPositiveClick}
          >
            {props.positiveButton || t('General.ok')}
          </Button>
        </div>
      </Modal.Card.Body>
    </ModalWrapper>
  );
}

export default SimpleModal;
