import { useTranslation } from 'react-i18next';
import Content from '../../components/core/content';
import Title from '../../components/core/title';
import NotAuthGuard from '../../components/guards/not-auth-guard';
import LoginForm from '../../components/login';

function LoginPage() {
  const { t } = useTranslation('translation');

  return (
    <NotAuthGuard>
      <Content width="360px">
        <Title className="has-text-centered mb-5">{t('Login.title')}</Title>
        <LoginForm />
      </Content>
    </NotAuthGuard>
  );
}

export default LoginPage;
