import { useState } from 'react';
import { Form, Icon } from 'react-bulma-components';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Eye } from '../../../../svgs/eye.svg';
import { ReactComponent as Lock } from '../../../../svgs/lock.svg';

function PasswordInput(
  props: { fieldName?: string; placeholder?: string } & any
) {
  const { t } = useTranslation('translation');
  const [show, setShow] = useState(false);

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fieldName = props.fieldName || 'password';
  const placeholder = props.placeholder || t('Login.password')!;

  return (
    <Controller
      render={({ field }) => (
        <Form.Control>
          <Form.Input
            {...props}
            placeholder={placeholder}
            name="password"
            color={errors[fieldName] ? 'danger' : 'text'}
            onBlur={() => field.onBlur()}
            onChange={(e) => field.onChange(e.target.value)}
            value={field.value}
            domRef={field.ref as any}
            type={show ? 'text' : 'password'}
            maxLength={1000}
          />
          <Icon align="left">
            <Lock />
          </Icon>

          <Icon
            align="right"
            className="is-clickable"
            onClick={() => setShow(!show)}
          >
            <Eye />
          </Icon>
        </Form.Control>
      )}
      control={control}
      name={fieldName}
    />
  );
}

export default PasswordInput;
