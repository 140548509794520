export default {
  General: {
    required: 'Required',
    cancel: 'Cancel',
    ok: 'OK',
    notLoggedIn: 'Please login to proceed',
  },
  Navbar: {
    logout: 'Logout',
    loggingOut: 'Logging out...',
    loggedOut: 'Logged out',
    logoutFailed: 'Failed to logout.',
  },
  Login: {
    title: 'Login',
    username: 'Username',
    password: 'Password',
    code: '2FA Code',
    remember: 'Remember me',
    submit: 'Login',
    loggedIn: 'Successfully logged in',
    errors: {
      auth: 'Your username or password is wrong.',
      unexpected: 'An unexpected error occurred.',
    },
  },
  TimeRecordings: {
    title: 'ABC Time Recording',
    date: 'Date',
    startTime: 'Start Time',
    endTime: 'End Time',
    project: 'Project/Line',
    homeOffice: 'Home Office',
    description: 'Description',
    cancel: 'Cancel',
    delete: 'Delete',
    submit: 'Submit',
    recordings: 'Recordings',
    release: 'Release period',
    releaseReturns: 'Release returns',
    returned: 'Returned',
    noRecordings: 'No recordings found for the selected week.',
    created: 'Successfully created.',
    updated: 'Successfully updated.',
    deleted: 'Successfully deleted.',
    released: 'Successfully released',
    deleteModal: 'Do you really want to delete this entry?',
    saldo: '{{hoursWork}}/{{hoursPlan}} - balance at end {{saldo}}',
    errors: {
      endTime: 'Must be later than start time.',
      projectId: 'No projects for selected date found.',
      create: 'Failed to create.',
      update: 'Failed to update.',
      delete: 'Failed to delete.',
      release: 'Failed to release.',
    },
  },
  TwoFactorSecrets: {
    title: 'Two Factor Secret Generator',
    amount: 'Amount',
    generate: 'Generate',
    errors: {
      unexpected: 'An unexpected error occurred.',
    },
  },
};
