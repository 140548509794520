import { useTranslation } from 'react-i18next';
import Content from '../../components/core/content';
import Title from '../../components/core/title';
import { Button, Form } from 'react-bulma-components';

import AuthGuard from '../../components/guards/auth-guard';
import styles from './index.module.scss';
import { Controller, useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { useState } from 'react';
import { generateTwoFactorSecrets } from '../../api/auth';
import { TwoFactorSecret } from '../../interfaces/two-factor-secret.interface';
import TwoFactorSecretList from '../../components/two-factor-secrets/list';
import ErrorNotification from '../../components/shared/error-notification';

const schema = Joi.object({
  amount: Joi.number().integer().min(1).required(),
});

function TwoFactorSecretsPage() {
  const { t } = useTranslation('translation');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [secrets, setSecrets] = useState<TwoFactorSecret[]>([]);

  const methods = useForm({
    resolver: joiResolver(schema),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    setError(undefined);

    try {
      const result = await generateTwoFactorSecrets(values.amount);
      setSecrets(result);
    } catch (err) {
      console.error('failed to generate', err);

      setError(t('TwoFactorSecrets.errors.unexpected')!);
    }

    setLoading(false);
  };

  return (
    <AuthGuard adminOnly>
      <Content width="940px">
        <Title className="mb-4">{t('TwoFactorSecrets.title')}</Title>
        <div className={styles.container}>
          <form className="mb-4" onSubmit={methods.handleSubmit(onSubmit)}>
            <Form.Field>
              <Form.Label>{t('TwoFactorSecrets.amount')}</Form.Label>
              <div className="flex">
                <Controller
                  render={({ field }) => (
                    <Form.Input
                      className={styles.amount + ' mr-3'}
                      name="amount"
                      type="number"
                      placeholder="1"
                      color={
                        methods.formState.errors.amount ? 'danger' : 'text'
                      }
                      onBlur={() => field.onBlur()}
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                      domRef={field.ref as any}
                      disabled={loading}
                    ></Form.Input>
                  )}
                  control={methods.control}
                  name="amount"
                />
                <Button disabled={loading}>
                  {t('TwoFactorSecrets.generate')}
                </Button>
              </div>
            </Form.Field>
          </form>
          <ErrorNotification
            error={error}
            onClose={() => setError(undefined)}
          />
          <TwoFactorSecretList items={secrets} />
        </div>
      </Content>
    </AuthGuard>
  );
}

export default TwoFactorSecretsPage;
