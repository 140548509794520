import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeRecordingsContext from '../../../context/time-recordings-context';
import TimeRecordingList from '../list';
import { Button } from 'react-bulma-components';
import toast from 'react-hot-toast';
import { releaseReturns } from '../../../api/time-recordings';

function ReturnedTimeRecordings() {
  const { t } = useTranslation();
  const { returned, setSelectedRecording, loadRecordings } = useContext(
    TimeRecordingsContext
  );
  const [loading, setLoading] = useState(false);

  const onRelease = async () => {
    setLoading(true);
    try {
      await releaseReturns();
      await loadRecordings();

      toast.success(t('TimeRecordings.released', { duration: 3000 }));
    } catch (err) {
      console.error('failed to release returned recordings', err);
      toast.error(t('TimeRecordings.errors.release', { duration: 3000 }));
    }
    setLoading(false);
  };

  return (
    <>
      {returned && returned.length > 0 && (
        <div className="mb-5">
          <p className="mb-1">{t('TimeRecordings.returned')}</p>
          <TimeRecordingList items={returned} onSelect={setSelectedRecording} />
          <div className="is-flex is-justify-content-end mt-5">
            <Button color="primary" onClick={onRelease} disabled={loading}>
              {t('TimeRecordings.releaseReturns')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default ReturnedTimeRecordings;
