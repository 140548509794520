import { useEffect, useState } from 'react';

export function useResizeBreakpoint(breakpoint: number) {
  const [onResizeBreakpoint, setOnResizeBreakpoint] = useState(false);

  useEffect(() => {
    setOnResizeBreakpoint(window.innerWidth <= breakpoint);

    const handleResize = () => {
      setOnResizeBreakpoint(window.innerWidth <= breakpoint);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { onResizeBreakpoint };
}
