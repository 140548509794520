import { endOfWeek } from 'date-fns';
import { useContext, useState } from 'react';
import { Button } from 'react-bulma-components';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { releaseRecordings } from '../../../api/time-recordings';
import TimeRecordingsContext from '../../../context/time-recordings-context';
import WeekSelect from '../../shared/week-select';
import TimeRecordingList from '../list';
import styles from './index.module.scss';

function TimeRecordings() {
  const { t } = useTranslation();
  const {
    recordings,
    selectedWeekDate,
    setSelectedWeekDate,
    setSelectedRecording,
    loadRecordings,
    saldo,
  } = useContext(TimeRecordingsContext);
  const [loading, setLoading] = useState(false);

  const onChangeWeek = (date: Date) => {
    console.log('change to', date);
    setSelectedWeekDate(date);
  };

  const onRelease = async () => {
    setLoading(true);
    try {
      const endDate = endOfWeek(selectedWeekDate, { weekStartsOn: 1 });

      await releaseRecordings(selectedWeekDate, endDate);
      await loadRecordings();

      toast.success(t('TimeRecordings.released', { duration: 3000 }));
    } catch (err) {
      console.error('failed to release recordings', err);
      toast.error(t('TimeRecordings.errors.release', { duration: 3000 }));
    }
    setLoading(false);
  };

  return (
    <>
      {recordings && (
        <>
          <div className="is-flex is-align-items-center is-flex-wrap-wrap mb-3">
            <p className="mr-3">{t('TimeRecordings.recordings')}</p>
            <WeekSelect
              selectedWeekDate={selectedWeekDate}
              minWeeks={+process.env.REACT_APP_MIN_WEEKS!}
              onChange={onChangeWeek}
            />
          </div>
          <TimeRecordingList
            items={recordings}
            onSelect={setSelectedRecording}
          />
          <div className={styles.footer + ' is-flex mt-5'}>
            {saldo && (
              <p style={{ lineHeight: 1 }}>
                {t('TimeRecordings.saldo', {
                  hoursWork: saldo.hoursWork.toFixed(2),
                  hoursPlan: saldo.hoursPlan.toFixed(2),
                  saldo: saldo.saldo.toFixed(2),
                })}
              </p>
            )}
            <Button
              className="ml-auto"
              color="primary"
              onClick={onRelease}
              disabled={loading}
            >
              {t('TimeRecordings.release')}
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default TimeRecordings;
