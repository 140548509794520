import { useEffect, useState } from 'react';
import { Form } from 'react-bulma-components';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getProjects } from '../../../../api/projects';
import { Project } from '../../../../interfaces/projects.interface';
import styles from './index.module.scss';
import parse from 'html-react-parser';

function ProjectSelect(props: { disabled?: boolean }) {
  const { t } = useTranslation('translation');
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [date, projectId] = watch(['date', 'projectId']);

  useEffect(() => {
    if (date) {
      fetchProjects();
    }
  }, [date]);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const result = await getProjects(date);
      setProjects(result);

      console.log('for', date, result, projectId);

      if (result.length < 1) {
        // no projects found for date, trigger error
        setValue('projectId', undefined, { shouldValidate: true });
      } else if (!result.find((p) => p.id === projectId)) {
        // selected project is not available anymore for new date, set first project
        setValue('projectId', result[0].id, { shouldValidate: true });
      }
    } catch (err) {
      console.log('failed to fetch projects', err);
    }
    setLoading(false);
  };

  const groupedProjects: { name: string; projects: Project[] }[] = [];

  for (const project of projects) {
    if (project.highlighted) {
      groupedProjects.push({ name: project.name, projects: [] });
      groupedProjects[groupedProjects.length - 1].projects.push(project);
    } else {
      groupedProjects[groupedProjects.length - 1].projects.push(project);
    }
  }

  const items = groupedProjects.map((group, index) => {
    return (
      <optgroup label={group.name} key={group.name}>
        {group.projects.map((p) => (
          <option key={p.id} value={p.id} disabled={p.locked}>
            {p.name}
          </option>
        ))}
      </optgroup>
    );
  });
  // const items = projects.map((p, index) => {
  //   return (
  //     <option style={{ fontWeight: '500' }} key={p.id} value={p.id}>
  //       {/* {parse(p.name.replace(/\s/g, '&nbsp;'))} */}
  //       {p.name}
  //     </option>
  //   );
  // });

  return (
    <Form.Field className={styles.container}>
      <Form.Label>{t('TimeRecordings.project')}</Form.Label>
      <Controller
        render={({ field }) => (
          <Form.Select
            className={styles.select}
            color={errors.projectId ? 'danger' : 'text'}
            disabled={props.disabled || loading}
            onBlur={() => field.onBlur()}
            value={field.value}
            onChange={(e) => field.onChange(e.target.value)}
            domRef={field.ref as any}
          >
            {items}
          </Form.Select>
        )}
        control={control}
        name="projectId"
      />

      {/* <Form.Select
        className={styles.select}
        color={errors.projectId ? 'danger' : 'text'}
        disabled={props.disabled || loading}
      >
        {items}
      </Form.Select> */}
      {errors.projectId && (
        <Form.Help color="danger">
          {t('TimeRecordings.errors.projectId')}
        </Form.Help>
      )}
    </Form.Field>
  );
}

export default ProjectSelect;
