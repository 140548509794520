import { Modal } from 'react-bulma-components';
import { PausedRequest } from '../../../context/auth-context';
import LoginForm from '../../login';
import ModalWrapper from '../modal-wrapper';

function LoginModal(props: {
  pausedRequest: PausedRequest;
  onLoggedIn: () => void;
}) {
  const onLogin = () => {
    props.pausedRequest.resolve();
    props.onLoggedIn();
  };

  return (
    <ModalWrapper size="small" show={true}>
      {/* <ModalHeader>{props.title}</ModalHeader> */}
      <Modal.Card.Body>
        <LoginForm onLogin={onLogin} />
      </Modal.Card.Body>
    </ModalWrapper>
  );
}

export default LoginModal;
