import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import de from 'date-fns/locale/de';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/core/navbar';
import { AuthContextProvider } from './context/auth-context';
import LoginPage from './pages/login';
import TimeRecordingsPage from './pages/time-recordings';
import {
  loginUrl,
  timeRecordingsUrl,
  twoFactorSecretsUrl,
} from './utils/routes';

import { registerLocale } from 'react-datepicker';
import TwoFactorSecretsPage from './pages/two-factor-secrets';
registerLocale('de', de);

// const theme = createTheme({
//   components: {
//     // Name of the component
//     MuiInputBase: {
//       defaultProps: {
//         // The props to change the default for.
//         disableRipple: true, // No more ripple, on the whole application 💣!
//       },
//     },
//   },
// });

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      <AuthContextProvider>
        <Navbar />
        <main>
          <Routes>
            <Route path={loginUrl} element={<LoginPage />} />
            <Route path={timeRecordingsUrl} element={<TimeRecordingsPage />} />
            <Route
              path={twoFactorSecretsUrl}
              element={<TwoFactorSecretsPage />}
            />
            <Route path="*" element={<TimeRecordingsPage />} />
          </Routes>
        </main>
        <Toaster position="top-center" />
      </AuthContextProvider>
    </LocalizationProvider>
  );
}

export default App;
