import { Form, Icon } from 'react-bulma-components';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Time } from '../../../../svgs/time.svg';
// import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';

function TimeInput(props: {
  fieldName?: string;
  label: string;
  disabled?: boolean;
}) {
  const { t } = useTranslation('translation');

  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const fieldName = props.fieldName || 'time';

  const validateInput = (value: string) => {
    console.log('handle value', value);
    if ((getValues(fieldName) || '').length > value.length) {
      return value;
    }

    let numbers = value.replace(/[^0-9]/g, '');

    if (numbers.length == 2) {
      if (+numbers > 23) {
        return '0' + numbers[0] + ':' + numbers[1];
      } else {
        return numbers + ':';
      }
    }

    if (numbers.length == 3 && +numbers[2] > 5) {
      return numbers.substring(0, numbers.length - 1);
    }

    if (numbers.length == 4 && +numbers.substring(2) >= 60) {
      return numbers.substring(0, numbers.length - 1);
    }

    if (value.length > 5) {
      return value.substring(0, value.length - 1);
    }
  };

  const formatResult = (value: string) => {
    value = value.replace(/[^0-9]/g, '');

    switch (value.length) {
      case 0:
        return '';
      case 1:
        return `0${value}:00`;
      case 2:
        return `${value}:00`;
      case 3:
        return `${value[0] + value[1]}:${value[2]}0`;
      default:
        return `${value[0] + value[1]}:${value[2] + value[3]}`;
    }
  };

  const onClickTime = () => {
    console.log('format me', format(new Date(), 'HH:mm'));
    setValue(fieldName, format(new Date(), 'HH:mm'), { shouldValidate: true });
  };

  return (
    <Controller
      render={({ field }) => (
        <Form.Control>
          <Form.Input
            placeholder="00:00"
            name={props.fieldName}
            onBlur={(e) => {
              const value = formatResult(e.target.value);
              console.log('update value', value);
              setValue(fieldName, value, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            onChange={(e) => {
              const value = validateInput(e.target.value);
              console.log('on change, ', e);
              field.onChange(value);
            }}
            value={field.value}
            domRef={field.ref as any}
            type="text"
            color={errors[fieldName] ? 'danger' : 'text'}
            disabled={props.disabled}
          />
          <Icon className="is-clickable" align="right" onClick={onClickTime}>
            <Time />
          </Icon>
        </Form.Control>
      )}
      control={control}
      name={fieldName}
    />
  );
}

export default TimeInput;
// function TimeInput(props: {
//   fieldName?: string;
//   label: string;
//   minTime?: Date;
//   disabled?: boolean;
// }) {
//   const { t } = useTranslation('translation');

//   const {
//     control,
//     setValue,
//     formState: { errors },
//   } = useFormContext();

//   const fieldName = props.fieldName || 'time';

//   return (
//     <Form.Control>
//       <Controller
//         render={({ field }) => {
//           // console.log(
//           //   'ma field',
//           //   field.value,
//           //   isNaN(new Date(field.value).getTime())
//           // );

//           let fieldValue = field.value;
//           if (isNaN(new Date(fieldValue).getTime())) {
//             fieldValue = setHours(setMinutes(new Date(), 0), 0);
//           }

//           return (
//             <DatePicker
//               dateFormat="HH:mm"
//               placeholderText="hh:mm"
//               selected={fieldValue}
//               onChange={(date) => field.onChange(date)}
//               ref={(ref) => {
//                 if (ref) {
//                   field.ref({
//                     focus: ref!.setFocus,
//                   });
//                 }
//               }}
//               className={'input' + (errors[fieldName] ? ' is-danger' : '')}
//               locale="de"
//               showTimeSelect
//               showTimeSelectOnly
//               timeIntervals={10}
//               timeCaption={props.label}
//               minTime={props.minTime || setHours(setMinutes(new Date(), 0), 0)}
//               maxTime={setHours(setMinutes(new Date(), 59), 23)}
//               disabled={props.disabled}
//             />
//           );
//         }}
//         control={control}
//         name={fieldName}
//       />
//       <Icon align="right">
//         <Time />
//       </Icon>
//     </Form.Control>
//   );
// }

// export default TimeInput;
