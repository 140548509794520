import { Project } from '../interfaces/projects.interface';
import myAxios from '../utils/my-axios';

export const getProjects = async (date: Date): Promise<Project[]> => {
  const { data } = await myAxios.get('/projects', {
    params: {
      date: date.toISOString(),
    },
  });

  return data.data;
};
