import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

// axiosAuthRedirector.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (err: AxiosError) => {

//     if (
//       err.response!.status === 401
//     ) {
//       router.push(
//         localizeUrl(
//           signInUrl({ error: 'loggedOut', ref: window.location.pathname }),
//           router.locale!
//         )
//       );
//     }
//     return Promise.reject(err);
//   }
// );

export default axiosInstance;
