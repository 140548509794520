import styles from './index.module.scss';

function Title(props: { className?: string; children: any }) {
  let className = styles.title;

  if (props.className) {
    className += ' ' + props.className;
  }

  return <h1 className={className}>{props.children}</h1>;
}

export default Title;
