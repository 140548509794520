import { endOfWeek, startOfWeek } from 'date-fns';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { getRecordings } from '../api/time-recordings';
import { TimeRecording } from '../interfaces/time-recording.interface';
import { timeRecordingsUrl } from '../utils/routes';
import { useLocation } from 'react-router-dom';
import AuthContext from './auth-context';
import { Saldo } from '../interfaces/saldo.interface';

interface TimeRecordingsContextType {
  selectedWeekDate: Date;
  setSelectedWeekDate: (date: Date) => void;
  selectedRecording?: TimeRecording;
  setSelectedRecording: (recording?: TimeRecording) => void;
  loading: boolean;
  recordings?: TimeRecording[];
  returned?: TimeRecording[];
  saldo?: Saldo;
  loadRecordings: () => Promise<void>;
}

const TimeRecordingsContext = createContext<TimeRecordingsContextType>({
  selectedWeekDate: new Date(),
  setSelectedWeekDate: {} as any,
  setSelectedRecording: {} as any,
  loadRecordings: {} as any,
  loading: false,
});

export function TimeRecordingsContextProvider(props: { children: any }) {
  const { pausedRequest } = useContext(AuthContext);
  const [selectedWeekDate, setSelectedWeekDate] = useState<Date>(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );

  const [loading, setLoading] = useState(false);

  const [recordings, setRecordings] = useState<TimeRecording[] | undefined>();
  const [returned, setReturned] = useState<TimeRecording[] | undefined>();
  const [saldo, setSaldo] = useState<Saldo | undefined>();

  const [selectedRecording, setSelectedRecording] = useState<
    TimeRecording | undefined
  >();

  const fetchInterval = useRef<any>();

  // clear fetch interval on leave
  useEffect(() => {
    return () => clearInterval(fetchInterval.current);
  }, []);

  // when week changes, refresh recordings and refresh interval
  useEffect(() => {
    loadRecordings();

    if (fetchInterval.current) {
      clearInterval(fetchInterval.current);
    }

    fetchInterval.current = setInterval(() => {
      loadRecordings();
    }, +process.env.REACT_APP_FETCH_INTERVAL_MILLIS!);
  }, [selectedWeekDate]);

  useEffect(() => {
    if (pausedRequest) {
      clearInterval(fetchInterval.current);
      fetchInterval.current = undefined;
    } else if (!fetchInterval.current) {
      fetchInterval.current = setInterval(() => {
        loadRecordings();
      }, +process.env.REACT_APP_FETCH_INTERVAL_MILLIS!);
    }
    return () => clearInterval(fetchInterval.current);
  }, [pausedRequest]);

  const loadRecordings = async () => {
    setLoading(true);
    try {
      const endDate = endOfWeek(selectedWeekDate, { weekStartsOn: 1 });

      const { timeRecordings, returned, saldo } = await getRecordings(
        selectedWeekDate,
        endDate
      );

      setRecordings(timeRecordings);
      setReturned(returned);
      setSaldo(saldo);

      console.log(
        'set recordings',
        selectedWeekDate,
        endDate,
        timeRecordings,
        returned
      );
    } catch (err) {
      console.error('failed to load recordings', err);
    }
    setLoading(false);
  };

  return (
    <TimeRecordingsContext.Provider
      value={{
        selectedWeekDate,
        setSelectedWeekDate,
        selectedRecording,
        setSelectedRecording,
        loading,
        recordings,
        returned,
        saldo,
        loadRecordings,
      }}
    >
      {props.children}
    </TimeRecordingsContext.Provider>
  );
}

export default TimeRecordingsContext;
