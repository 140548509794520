import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../context/auth-context';
import { loginUrl } from '../../../utils/routes';

function AuthGuard(props: { children: any; adminOnly?: boolean }) {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user || (props.adminOnly && !user.admin)) {
      navigate(loginUrl);
    }
  }, []);

  return <>{user && props.children}</>;
}

export default AuthGuard;
