import { TwoFactorSecret } from '../interfaces/two-factor-secret.interface';
import { User } from '../interfaces/user.interface';
import myAxios from '../utils/my-axios';

export const login = async (
  name: string,
  password: string,
  code: string,
  remember?: boolean
): Promise<User> => {
  const { data } = await myAxios.post('/login', {
    name,
    password,
    code,
    remember,
  });

  return data.data;
};
export const logout = async (): Promise<void> => {
  await myAxios.post('/logout');
};

export const getMe = async (): Promise<User> => {
  const { data } = await myAxios.get('/me');

  return data.data;
};

export const generateTwoFactorSecrets = async (
  amount: number
): Promise<TwoFactorSecret[]> => {
  const { data } = await myAxios.post('/generate-two-factor-secrets', {
    amount,
  });

  return data.data;
};
