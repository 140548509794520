import { endOfWeek, format, startOfWeek, subDays } from 'date-fns';
import { Form } from 'react-bulma-components';

function WeekSelect(props: {
  selectedWeekDate?: Date;
  minWeeks: number;
  onChange: (date: Date) => void;
  disabled?: boolean;
}) {
  let currentDate = new Date();

  let items: any[] = [];

  for (let i = 0; i < props.minWeeks; i++) {
    let date = startOfWeek(subDays(currentDate, 7 * i), { weekStartsOn: 1 });

    let text = `${format(date, 'dd.MM')} - ${format(
      endOfWeek(date, { weekStartsOn: 1 }),
      'dd.MM.yyyy'
    )}`;

    items.push(
      <option key={text} value={date.toISOString()}>
        {text}
      </option>
    );
  }

  const onChange = (ev: any) => {
    props.onChange(new Date(ev.target.value));
  };

  return (
    <Form.Select onChange={onChange} disabled={props.disabled}>
      {items}
    </Form.Select>
  );
}

export default WeekSelect;
