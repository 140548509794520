import { useTranslation } from 'react-i18next';
import { TwoFactorSecret } from '../../../interfaces/two-factor-secret.interface';
import TwoFactorSecretItem from './item';

function TwoFactorSecretList(props: { items: TwoFactorSecret[] }) {
  const { t } = useTranslation();
  const items = props.items.map((item) => {
    return <TwoFactorSecretItem item={item} key={item.secret} />;
  });

  return <div>{items}</div>;
}

export default TwoFactorSecretList;
