import { useEffect } from 'react';
import { Button, Notification } from 'react-bulma-components';

function ErrorNotification(props: { error?: any; onClose: () => void }) {
  useEffect(() => {
    if (props.error) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [props.error]);

  return (
    <>
      {props.error && (
        <Notification color="danger">
          {props.error}
          <Button remove onClick={props.onClose} />
        </Notification>
      )}
    </>
  );
}

export default ErrorNotification;
