import axios, { AxiosError } from 'axios';
import { createContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getMe } from '../api/auth';
import LoginModal from '../components/shared/login-modal';
import { User } from '../interfaces/user.interface';
import myAxios from '../utils/my-axios';
import { timeRecordingsUrl } from '../utils/routes';

interface AuthContextType {
  user?: User;
  pausedRequest?: PausedRequest;
  login: (user: User, remember?: boolean) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  login: (user: User) => {},
  logout: () => {},
});

export interface PausedRequest {
  resolve: (value?: any) => void;
  reject: (value: any) => void;
}

export function AuthContextProvider(props: any) {
  const [user, setUser] = useState<User | undefined>();
  const [initialized, setInitialized] = useState(false);
  const [pausedRequest, setPausedRequest] = useState<PausedRequest>();
  const { t } = useTranslation();

  const navigate = useNavigate();

  // init by fetching profile
  useEffect(() => {
    fetchProfile();
  }, []);

  // prompt for login form when getting 401 error
  useEffect(() => {
    if (initialized) {
      myAxios.interceptors.response.clear();
      myAxios.interceptors.response.use(
        undefined,
        async (error: AxiosError) => {
          if (error.response?.status !== 401) {
            return Promise.reject(error);
          }

          const promise = new Promise((resolve, reject) => {
            toast.error(t('General.notLoggedIn'));
            setPausedRequest({ resolve, reject });
          }).catch((err) => Promise.reject(error));

          // delay original requests until login modal feedback
          await promise;

          return axios.request(error.config!);
        }
      );
    }
  }, [setPausedRequest, initialized]);

  const fetchProfile = async () => {
    try {
      const user = await getMe();
      setUser(user);
    } catch (err) {
      console.error('failed to fetch profile', err);
    }

    setInitialized(true);
  };

  const login = (user: User, remember?: boolean) => {
    setUser(user);
    navigate(timeRecordingsUrl);
  };

  const logout = () => {
    setUser(undefined);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        pausedRequest,
        login,
        logout,
      }}
    >
      {initialized && props.children}
      {!initialized && (
        <div className="loading is-flex is-justify-content-center">
          <img src="/images/loading.png" width="30" height="30" />
        </div>
      )}
      {pausedRequest && (
        <LoginModal
          pausedRequest={pausedRequest}
          onLoggedIn={() => setPausedRequest(undefined)}
        />
      )}
    </AuthContext.Provider>
  );
}

export default AuthContext;
