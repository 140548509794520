import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bulma-components';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  createRecording,
  deleteRecording,
  updateRecording,
} from '../../../api/time-recordings';
import TimeRecordingsContext from '../../../context/time-recordings-context';
import { useResizeBreakpoint } from '../../../hooks/use-resize-breakpoint';
import DateInput from '../../shared/form/date';
import TimeInput from '../../shared/form/time';
import SimpleModal from '../../shared/simple-modal';
import styles from './index.module.scss';
import ProjectSelect from './project-select';

export const timeRegex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;

const schema = Joi.object({
  date: Joi.date().required(),
  startTime: Joi.string().regex(timeRegex).required(),
  endTime: Joi.string()
    .regex(timeRegex)
    .custom((value, helpers) => {
      const {
        state: {
          ancestors: [{ startTime }],
        },
      } = helpers;

      if (
        timeRegex.test(startTime) &&
        timeRegex.test(value) &&
        +startTime.replace(':', '') >= +value.replace(':', '')
      ) {
        console.log('min error!!!!');
        return helpers.error('min');
      }

      return value;
    })
    .required(),
  projectId: Joi.number().required(),
  description: Joi.string().required(),
  homeOffice: Joi.bool(),
});

const formatTimeString = (time: string) => {
  const split = time.split(':');

  if (split.length > 2) split.pop();

  return split.join(':');
};

function TimeRecordingsForm(props: { onLogin?: () => void }) {
  const { t } = useTranslation('translation');
  const { selectedRecording, setSelectedRecording, loadRecordings } =
    useContext(TimeRecordingsContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { onResizeBreakpoint } = useResizeBreakpoint(650);

  const methods = useForm({
    resolver: joiResolver(schema),
    // mode: 'all',
  });

  useEffect(() => {
    methods.setValue('date', new Date());
  }, []);

  // update form whenever recording changes
  useEffect(() => {
    if (!selectedRecording) {
      onReset();
    } else {
      methods.setValue('date', new Date(selectedRecording.date), {
        shouldValidate: true,
      });
      methods.setValue(
        'startTime',
        formatTimeString(selectedRecording.startTime),
        {
          shouldValidate: true,
        }
      );
      methods.setValue('endTime', formatTimeString(selectedRecording.endTime), {
        shouldValidate: true,
      });
      methods.setValue('projectId', selectedRecording.projectId, {
        shouldValidate: true,
      });
      methods.setValue('description', selectedRecording.description, {
        shouldValidate: true,
      });
      methods.setValue('homeOffice', selectedRecording.homeOffice, {
        shouldValidate: true,
      });

      setError(undefined);
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [selectedRecording]);

  // update or create on submit
  const onSubmit = async (values: any) => {
    console.log('onsubmit', methods.getValues());
    setLoading(true);

    if (selectedRecording) {
      await update(values);
    } else {
      await create(values);
    }

    setLoading(false);
  };

  // create recording
  const create = async (values: any) => {
    try {
      await createRecording(
        values.date,
        values.startTime,
        values.endTime,
        values.projectId,
        values.description,
        values.homeOffice || false
      );

      await loadRecordings();

      methods.setValue('startTime', '');
      methods.setValue('endTime', '');
      methods.setValue('description', '');

      toast.success(t('TimeRecordings.created'), { duration: 2000 });

      setError(undefined);
    } catch (err: any) {
      console.error('failed to create recording', err, values);
      toast.error(t('TimeRecordings.errors.create'), { duration: 3000 });
      setError(
        err.response
          ? err.response.data.message
          : t('TimeRecordings.errors.create')
      );
    }
  };

  // update recording
  const update = async (values: any) => {
    try {
      await updateRecording(
        new Date(selectedRecording!.date),
        selectedRecording!.startTime,
        values.date,
        values.startTime,
        values.endTime,
        values.projectId,
        values.description,
        values.homeOffice || false
      );

      await loadRecordings();

      onReset();

      toast.success(t('TimeRecordings.updated'), { duration: 2000 });

      setError(undefined);
    } catch (err: any) {
      console.error('failed to update recording', err, values);
      toast.error(t('TimeRecordings.errors.update'), { duration: 3000 });

      setError(
        err.response
          ? err.response.data.message
          : t('TimeRecordings.errors.update')
      );
    }
  };

  // delete recording
  const onDelete = async () => {
    setLoading(true);
    try {
      await deleteRecording(
        new Date(selectedRecording!.date),
        selectedRecording!.startTime
      );

      await loadRecordings();

      onReset();

      toast.success(t('TimeRecordings.deleted'), { duration: 2000 });

      setError(undefined);
    } catch (err: any) {
      console.error('failed to delete recording', err, selectedRecording);
      toast.error(t('TimeRecordings.errors.delete'), { duration: 3000 });

      setError(
        err.response
          ? err.response.data.message
          : t('TimeRecordings.errors.delete')
      );
    }
    setLoading(false);
  };

  const onReset = () => {
    setSelectedRecording(undefined);

    methods.setValue('homeOffice', false);
    methods.setValue('startTime', '');
    methods.setValue('endTime', '');
    methods.setValue('description', '');

    setError(undefined);
  };

  const homeOffice = (
    <Form.Field className={styles['home-office']}>
      <Form.Label>{t('TimeRecordings.homeOffice')}</Form.Label>
      <Controller
        render={({ field }) => (
          <Form.Checkbox
            name="homeOffice"
            onBlur={() => field.onBlur()}
            onChange={(e) => field.onChange(e.target.checked)}
            checked={field.value}
            domRef={field.ref as any}
            disabled={loading}
          >
            <span></span>
          </Form.Checkbox>
        )}
        control={methods.control}
        name="homeOffice"
      />
    </Form.Field>
  );

  const errors = methods.formState.errors;

  return (
    <FormProvider {...methods}>
      <form
        className={styles.container}
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <div className={styles['form-row']}>
          <Form.Field className={styles.date}>
            <Form.Label>{t('TimeRecordings.date')}</Form.Label>
            <DateInput disabled={loading} />
          </Form.Field>

          <Form.Field className={styles.time}>
            <Form.Label>{t('TimeRecordings.startTime')}</Form.Label>
            <TimeInput
              fieldName="startTime"
              label={t('TimeRecordings.startTime')}
              disabled={loading}
            />
          </Form.Field>
          <Form.Field className={styles.time}>
            <Form.Label>{t('TimeRecordings.endTime')}</Form.Label>
            <TimeInput
              fieldName="endTime"
              label={t('TimeRecordings.endTime')}
              disabled={loading}
            />
            {errors.endTime && errors.endTime.type === 'date.greater' && (
              <Form.Help color="danger">
                {t('TimeRecordings.errors.endTime')}
              </Form.Help>
            )}
          </Form.Field>
        </div>
        <div className={styles['form-row']}>
          <ProjectSelect disabled={loading} />

          {!onResizeBreakpoint && homeOffice}
        </div>

        <div className={styles['form-row']}>
          <Form.Field className={styles.description + ' mb-0'}>
            <Form.Label>{t('TimeRecordings.description')}</Form.Label>

            <Controller
              render={({ field }) => (
                <Form.Textarea
                  name="description"
                  color={errors.description ? 'danger' : 'text'}
                  onBlur={() => field.onBlur()}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value}
                  domRef={field.ref as any}
                  maxLength={1000}
                  disabled={loading}
                ></Form.Textarea>
              )}
              control={methods.control}
              name="description"
            />
          </Form.Field>
        </div>

        {onResizeBreakpoint && (
          <div className={styles['form-row']}>{homeOffice}</div>
        )}

        {error && (
          <div className={styles['form-row']}>
            <p className="has-text-danger">{error}</p>
          </div>
        )}

        <div className={styles['form-row'] + ' ' + styles['buttons']}>
          <Button
            color="primary"
            type="button"
            onClick={onReset}
            disabled={loading}
          >
            {t('TimeRecordings.cancel')}
          </Button>
          <Button
            className={
              styles.delete +
              (selectedRecording ? ' ' + styles['delete--visible'] : '')
            }
            color="primary"
            type="button"
            disabled={loading}
            onClick={() => setShowDeleteModal(true)}
          >
            {t('TimeRecordings.delete')}
          </Button>
          <Button className="highlight" color="primary" disabled={loading}>
            {t('TimeRecordings.submit')}
          </Button>
        </div>

        {showDeleteModal && (
          <SimpleModal
            message={t('TimeRecordings.deleteModal')!}
            positiveButton={t('TimeRecordings.delete')!}
            onPositiveClick={onDelete}
            onClose={() => setShowDeleteModal(false)}
          />
        )}
      </form>
    </FormProvider>
  );
}

export default TimeRecordingsForm;
