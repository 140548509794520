import { useTranslation } from 'react-i18next';
import Content from '../../components/core/content';
import Title from '../../components/core/title';
import TimeRecordingsForm from '../../components/time-recordings/form';
import TimeRecordings from '../../components/time-recordings/recordings';
import ReturnedTimeRecordings from '../../components/time-recordings/returned';
import { TimeRecordingsContextProvider } from '../../context/time-recordings-context';

import AuthGuard from '../../components/guards/auth-guard';
import styles from './index.module.scss';

function TimeRecordingsPage() {
  const { t } = useTranslation('translation');
  // useAuthGuard();

  return (
    <AuthGuard>
      <Content width="940px">
        <Title className="mb-4">{t('TimeRecordings.title')}</Title>
        <div className={styles.container}>
          <TimeRecordingsContextProvider>
            <TimeRecordingsForm />
            <ReturnedTimeRecordings />
            <TimeRecordings />
          </TimeRecordingsContextProvider>
        </div>
      </Content>
    </AuthGuard>
  );
}

export default TimeRecordingsPage;
