import { Saldo } from '../interfaces/saldo.interface';
import { TimeRecording } from '../interfaces/time-recording.interface';
import myAxios from '../utils/my-axios';

export const getRecordings = async (
  startDate: Date,
  endDate: Date
): Promise<{
  timeRecordings: TimeRecording[];
  returned: TimeRecording[];
  saldo: Saldo;
}> => {
  const { data } = await myAxios.get('/time-recordings', {
    params: {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    },
  });

  return data.data;
};

export const createRecording = async (
  date: Date,
  startTime: string,
  endTime: string,
  projectId: number,
  description: string,
  homeOffice: boolean
): Promise<void> => {
  const { data } = await myAxios.post('/time-recordings', {
    date: date.toISOString(),
    startTime: startTime,
    endTime: endTime,
    projectId,
    description,
    homeOffice,
  });

  return data.data;
};

export const updateRecording = async (
  oldDate: Date,
  oldStartTime: string,
  date: Date,
  startTime: string,
  endTime: string,
  projectId: number,
  description: string,
  homeOffice: boolean
): Promise<void> => {
  const { data } = await myAxios.patch('/time-recordings', {
    oldDate: oldDate.toISOString(),
    oldStartTime: oldStartTime,
    date: date.toISOString(),
    startTime: startTime,
    endTime: endTime,
    projectId,
    description,
    homeOffice,
  });

  return data.data;
};

export const deleteRecording = async (
  date: Date,
  startTime: string
): Promise<{ timeRecordings: TimeRecording[]; returned: TimeRecording[] }> => {
  const { data } = await myAxios.post('/time-recordings/delete', {
    date: date.toISOString(),
    startTime: startTime,
  });

  return data.data;
};

export const releaseRecordings = async (
  startDate: Date,
  endDate: Date
): Promise<void> => {
  const { data } = await myAxios.post('/time-recordings/release', {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return data.data;
};

export const releaseReturns = async (): Promise<{
  timeRecordings: TimeRecording[];
  returned: TimeRecording[];
}> => {
  const { data } = await myAxios.post('/time-recordings/release-returns');

  return data.data;
};
